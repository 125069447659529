* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}
a {
  color: white;
  font-weight: 100;
  font-style: bold;
}
